<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="headerTitle"
		tooltipMsg="pmanualofdetail3452"
		:showActionDelete="false"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	></detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceCommon from '@/services/service/common.service';
import apiCommon from '@/services/api/common.api';
import loading from '@/mixins/loading.mixin';
import { model, fields } from './price-list-unit-form.data';

export default {
	components: {
		DetailPage,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'PriceListUnit',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		headerTitle() {
			const title = this.$t('detailofpricety');
			const name = this.model.OplunitNameSk || '';
			const id = this.$t('id');
			const idNum = this.model.OplunitId;
			return `${title} ${name} ${id} ${idNum}`;
		},
	},

	methods: {
		transformModelToDto(model) {
			const dtoModel = {
				...model,
			};

			if (typeof model.AccomodationUnitTypes === 'string') {
				dtoModel.AccomodationUnitTypes = model.AccomodationUnitTypes.split(',');
			}

			return dtoModel;
		},

		async loadResources() {
			await this.loadAccommodationUnitTypes();
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.OplunitId, this.$modal, this.deleteModel);
		},

		async loadAccommodationUnitTypes() {
			const values = await apiCommon
				.getStandardList({}, 'AccomodationUnitType')
				.then((res) => res.data)
				.then((res) => res.map((accUT) => ({ value: accUT.AutypeId, name: accUT.AutypeNameSk })));
			// console.debug('loadAccommodationUnitTypes: ', values);

			this.updateSchemaFields({ AccomodationUnitTypes: { values } });
		},
	},
};
</script>