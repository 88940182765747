import vueFormGenerator from 'vue-form-generator';

export const model = {
	OplunitId: 0,
	OplunitNameSk: null,
	OplunitNameCz: null,
	OplunitNamePl: null,
	OplunitNameDe: null,
	OplunitNameEn: null,
	OplunitNameHu: null,
	OplunitIsActive: false,
	OplunitIsGeneral: false,
	OplunitIsAssigned: false,
	AccomodationUnitTypes: [],
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'OplunitNameSk',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OplunitNameCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OplunitNamePl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OplunitNameEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OplunitNameDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OplunitNameHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'switch',
				model: 'OplunitIsActive',
				i18n: {
					label: 'active:',
					textOn: 'yes2205',
					textOff: 'no2206',
				},
			},
			{
				type: 'switch',
				model: 'OplunitIsGeneral',
				i18n: {
					label: 'displayedingene',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'OplunitIsAssigned',
				i18n: {
					label: 'displayedinujpr',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'AccomodationUnitTypes',
				values: [],
				i18n: {
					label: 'displayedinthes',
				},
				visible: function(model) {
					return model.OplunitIsAssigned;
				},
			},
		],
	},
];
